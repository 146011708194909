<template>
  <div>
    <b-modal v-model="editItemModal.show"
             hide-footer
             :title="editItemModal.item.id ? $t('Edit') : $t('Generate')"
    >
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="editItemModal.item.name"
          type="text"
          size="sm"
        />
      </b-form-group>
      <b-button variant="primary"
                :disabled="loading"
                size="sm"
                @click="generateNewApiKey"
      >
        <feather-icon v-if="editItemModal.item.id"
                      icon="KeyIcon"
        />
        {{ editItemModal.item.id ? $t('Save') : $t('Generate') }}
      </b-button>
    </b-modal>

    <b-card>
      <h4 class="d-flex align-items-center justify-content-between">
        <div>
          {{ $t('Calls') }}
        </div>
        <b-button variant="primary"
                  :disabled="loading"
                  size="sm"
                  class="ml-25"
                  @click="loginProcess"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('Add') }}
        </b-button>
      </h4>

      <b-table v-if="items.length"
               :items="items"
               :fields="fields"
               :busy="loading"
               striped
      >
        <template #head()="{ label }">
          {{ $t(`${label}`) }}
        </template>

        <template #cell(integrationGoogleConnectionGoogleAdsAccounts)="row">
          <b-button
            v-if="row.value && row.value.length"
            :style="{ visibility: !isSubcomponent ? 'visible' : 'hidden' }"
            size="sm"
            class="mr-2 btn-icon"
            variant="outline-primary"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'" />
          </b-button>
          <sw-icon v-else
                   icon="MinusIcon"
          />
        </template>

        <!-- Subcategory Section -->
        <template #row-details="row">
          <b-row>
            <b-col v-for="(a, aIndex) in row.item.integrationGoogleConnectionGoogleAdsAccounts"
                   :key="`${aIndex}_${row.index}`"
                   sm="12"
                   md="6"
            >
              <b-card class="border rounded">
                <div class="d-flex justify-content-between align-items-center">
                  <div>{{ a.accountId }}</div>
                  <div>
                    <b-button
                      variant="flat-warning"
                      class="btn-icon ml-25"
                      size="sm"
                      @click="openAddManagerModal(row.item, aIndex, a)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      variant="flat-danger"
                      class="btn-icon ml-25"
                      size="sm"
                      @click="removeAccount(row.index, aIndex, a, row.item)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </template>
        <template #cell(createdBy)="{ value }">
          <avatar :user="value" />
        </template>

        <template #cell(apiKey)="{ value }">
          <b-badge variant="light-success"
                   class="cursor-pointer"
                   @click="copyValue(value)"
          >
            <feather-icon icon="CopyIcon" />
            {{ value }}
          </b-badge>
        </template>

        <template #cell(action)="{ item, index }">
          <b-dropdown
            id="dropdown-dropleft"
            dropleft
            no-caret
            variant="flat"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" />
            </template>
            <b-dropdown-item @click="openEditModal(item)">
              <dropdown-item-label icon="EditIcon"
                                   :label="$t('Edit')"
              />
            </b-dropdown-item>
            <b-dropdown-item @click="openAddManagerModal(item, index)">
              <dropdown-item-label icon="UserPlusIcon"
                                   :label="$t('AddManagerAccount')"
              />
            </b-dropdown-item>
            <b-dropdown-item @click="removeApiKey(item, index)">
              <dropdown-item-label icon="TrashIcon"
                                   :label="$t('Delete')"
              />
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!--    Other    -->
        <template #cell()="row">
          <table-default-cell
            :field="row.field"
            :value="row.value"
          />
        </template>

        <!--      Empty      -->
        <template #empty>
          <empty-content />
        </template>

        <!--      Table Busy      -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              variant="primary"
            />
          </div>
        </template>
      </b-table>

      <div
        v-else
        class="text-center py-25 font-weight-bold text-primary"
      >
        <feather-icon
          size="17"
          icon="XCircleIcon"
        />

        {{ $t('NoData') }}
      </div>
    </b-card>

    <b-modal v-model="addManagerModal.show"
             hide-footer
             :title="addManagerModal.item.id ? $t('Edit') : $t('AddManagerAccount')"
    >
      <b-form-group :label="$t('AccountId')">
        <b-form-input v-model="addManagerModal.item.accountId" /></b-form-group>
      <b-form-group :label="$t('DeveloperToken')">
        <b-form-input v-model="addManagerModal.item.developerToken"
                      type="password"
        />
      </b-form-group>
      <!--      <b-form-group :label="$t('Name')">-->
      <!--        <b-form-input-->
      <!--          v-model="addManagerModal.item.name"-->
      <!--          type="text"-->
      <!--          size="sm"-->
      <!--        />-->
      <!--      </b-form-group>-->
      <b-button variant="primary"
                :disabled="loading"
                size="sm"
                @click="addGoogleAccount"
      >
        <feather-icon v-if="addManagerModal.item.id"
                      icon="KeyIcon"
        />
        {{ addManagerModal.item.id ? $t('Save') : $t('Add') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { formatDate } from '@core/utils/filter'
import { BModal, BTable } from 'bootstrap-vue'

const defaultItem = {
  name: '',
  code: '',
}

const defaultAccountItem = {
  accountId: '',
  developerToken: '',
}
export default {
  name: 'Zapier',
  components: {
    BModal,
    BTable,
  },
  data: () => ({
    loading: true,
    items: [],

    editItemModal: {
      show: false,
      item: JSON.parse(JSON.stringify(defaultItem)),
    },

    addManagerModal: {
      show: false,
      connection: null,
      item: JSON.parse(JSON.stringify(defaultAccountItem)),
    },

    fields: [
      { key: 'name', label: 'Name' },
      { key: 'userTokenActive', label: 'Status' },
      { key: 'integrationGoogleConnectionGoogleAdsAccounts', label: 'ManagerAccounts' },
      { key: 'createdBy', label: 'CreatedBy' },
      { key: 'createdAt', label: 'CreatedAt' },
      { key: 'action', label: 'Action' },
    ],
  }),
  mounted() {
    this.loadData()

    if (this.$route.query?.google_code) {
      this.editItemModal.item.code = this.$route.query.google_code
      this.editItemModal.show = true
      window.history.replaceState({}, null, '/settings/integrations/google')
    }
  },
  methods: {
    formatDate,
    loginProcess() {
      const { origin } = window.location
      window.location.replace(`https://google.saleswizardapp.com/?app=${origin}`)
    },
    openAddManagerModal(connection, index, item = defaultAccountItem) {
      // eslint-disable-next-line no-underscore-dangle
      const _item = {
        ...item,
      }

      if (_item.developerTokenExists) {
        _item.developerToken = '*******************'
      }
      this.$set(this, 'addManagerModal', {
        show: true,
        connection,
        index,
        item: _item,
      })
    },
    copyValue(value) {
      this.$copyText(value, this.$el).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('error', e)
      })
    },
    openEditModal(item = defaultItem) {
      this.$set(this.editItemModal, 'show', true)
      this.$set(this.editItemModal, 'item', JSON.parse(JSON.stringify(item)))
    },
    async generateNewApiKey() {
      this.loading = true
      try {
        const payload = {
          name: this.editItemModal.item.name,
        }

        if (this.editItemModal.item.id) {
          payload.id = this.editItemModal.item.id
          delete payload.code
          await axiosIns.patch('1/integration/google/connections', payload)
        } else {
          payload.code = this.editItemModal.item.code
          await axiosIns.post('1/integration/google/connections', payload)
        }

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.$set(this.editItemModal, 'show', false)
        this.$set(this.editItemModal, 'item', JSON.parse(JSON.stringify(defaultItem)))
        await this.loadData()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async addGoogleAccount() {
      this.loading = true
      try {
        const payload = {
          accountId: this.addManagerModal.item.accountId,
          developerToken: this.addManagerModal.item.developerToken,
        }

        if (payload.developerToken.includes('*****')) {
          delete payload.developerToken
        }

        if (this.addManagerModal.item.id) {
          payload.id = this.addManagerModal.item.id
          delete payload.developerTokenExists
          await axiosIns.patch(`1/integration/google/connections/${this.addManagerModal.connection.id}/googleAdsAccounts`, payload)
        } else {
          payload.code = this.addManagerModal.item.code
          await axiosIns.post(`1/integration/google/connections/${this.addManagerModal.connection.id}/googleAdsAccounts`, payload)
        }

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.$set(this.addManagerModal, 'show', false)
        this.$set(this.addManagerModal, 'item', JSON.parse(JSON.stringify(defaultAccountItem)))
        await this.loadData()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async removeApiKey(item) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      await axiosIns.delete('1/integration/google/connections', { data: JSON.stringify([item.id]) })
      this.showToast('success', this.$i18n.t('success.contactUpdated'))

      await this.loadData()
    },
    async removeAccount(index, accountIndex, item, connection) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      await axiosIns.delete(`1/integration/google/connections/${connection.id}/googleAdsAccounts`, { data: JSON.stringify([item.id]) })
      this.showToast('success', this.$i18n.t('success.contactUpdated'))

      await this.loadData()
    },
    async loadData() {
      this.loading = true

      try {
        const response = await axiosIns.get('1/integration/google/connections?fields_load=GOOGLE')
        console.log(response)

        if (response.data?.data?.items) {
          this.items = response.data.data.items || []
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
