<template>
  <sw-tabs-search
    v-model="activePanel"
    :tabs="list"
  >
    <sw-tabs
      :tabs="activePanel ? activePanel.children : []"
      :card="false"
      :active-tab-key="!$route.query.google_code ? $route.fullPath.split('settings/').pop().split('/')[1] : 'google'"
      dynamic-url
      @input="(activePanel && activePanel.children[$event]) && !$route.query.google_code && changeRoute(activePanel.key, activePanel.children[$event].key)"
    />
  </sw-tabs-search>
</template>

<script>
import System from '@/pages/settings/System.vue'
import SmsSenders from '@/pages/settings/SmsSenders.vue'
import GroupSettings from '@/pages/settings/GroupSettings.vue'
import Ranks from '@/pages/settings/Ranks.vue'
import ContactSettings from '@/pages/settings/ContactSettings.vue'
import Templates from '@/pages/settings/Templates.vue'
// import Integrations from '@/pages/settings/Integrations.vue'
import ThreadSettings from '@/pages/settings/ThreadSettings.vue'
import Polls from '@/pages/settings/Polls.vue'
import Applications from '@/pages/settings/Applications.vue'
import CloseReasons from '@/pages/settings/applications/CloseReasons.vue'
import OfferSettings from '@/pages/settings/OfferSettings.vue'
import Shop from '@/pages/settings/Shop.vue'
import List from '@/pages/website/List.vue'
import ProductPictureTypes from '@/pages/settings/ProductPictureTypes.vue'
import ContractsGenerator from '@/pages/settings/ContractsGenerator.vue'
import AgreementTypes from '@/pages/settings/AgreementTypes.vue'
import ComponentsTemplate from '@/pages/settings/ComponentsTemplate.vue'
import Status from '@/pages/settings/projects/Status.vue'
import TagsList from '@/pages/settings/projects/TagsList.vue'
import CategoriesList from '@/pages/settings/projects/CategoriesList.vue'
import Ai from '@/pages/settings/ai/Ai.vue'
// import ChatSettings from '@/pages/settings/chats/ChatSettings.vue'
// import ChatAuthorizedUsers from '@/pages/settings/chats/ChatAuthorizedUsers.vue'
// import AiConnections from '@/pages/settings/chats/AiConnections.vue'
import BugReports from '@/pages/settings/ai/BugReports.vue'
import Reports from '@/pages/settings/ai/Reports.vue'
import Automations from '@/pages/settings/Automations.vue'
import AutomationLogs from '@/pages/settings/AutomationLogs.vue'
import Autenti from '@/pages/settings/integrations/Autenti.vue'
import ImageRecognition from '@/pages/settings/integrations/ImageRecognition.vue'
import Fakturownia from '@/pages/settings/integrations/Fakturownia.vue'
import Zapier from '@/pages/settings/integrations/Zapier.vue'
import Make from '@/pages/settings/integrations/Make.vue'
import Ringostat from '@/pages/settings/integrations/Ringostat.vue'
import MobileApp from '@/pages/settings/mobileApp/MobileApp.vue'
import WhatsUp from '@/pages/settings/chats/WhatsUp.vue'
import ChatWidget from '@/pages/settings/chats/ChatWidget.vue'
import { SHOW_SYSTEM_MENU_AGREEMENTS, SHOW_SYSTEM_MENU_PROJECTS } from '@/helpers/viewPermissions'
import { AUTOMATION_VIEW_ALL } from '@/helpers/permissions'
import ContactGenerator from '@/pages/settings/ContactGenerator.vue'
import Google from '@/pages/settings/integrations/Google.vue'

export default {
  data: () => ({
    search: '',
    activePanel: null,

    tabs: [
      // System
      {
        title: 'System',
        key: 'system',
        icon: 'HomeIcon',
        children: [
          {
            title: 'General',
            key: 'general',
            component: System,
          },
          {
            title: 'Groups',
            key: 'groups',
            component: GroupSettings,
          },
          {
            title: 'RanksSettings',
            key: 'ranks',
            component: Ranks,
          },
          {
            title: 'TemplateSettings',
            key: 'template',
            component: Templates,
          },
          {
            title: 'SmsSenders',
            key: 'smsSenders',
            component: SmsSenders,
            requiredPermissions: ['FULL_ACCESS'],
          },
          // {
          //   title: 'Integrations',
          //   component: Integrations,
          // },
          // {
          //   title: 'Automations',
          //   component: Automations,
          // },
        ],
      },
      {
        title: 'MobileApp',
        key: 'mobileApp',
        icon: 'PhoneIcon',
        children: [
          {
            title: 'General',
            key: 'general',
            component: MobileApp,
          },
        ],
        requiredModule: 'clientMobileModule',
      },
      {
        title: 'Chats',
        key: 'chats',
        icon: 'MessageCircleIcon',
        children: [
          {
            title: 'WhatsApp',
            key: 'whatsApp',
            component: WhatsUp,
            requiredModule: 'whatsappModule',
          },
          {
            title: 'ChatWidget',
            key: 'chatwidget',
            component: ChatWidget,
            requiredModule: 'clientBrowserModule',
          },
          // {
          //   title: 'Autoryzowani użytkownicy',
          //   component: ChatAuthorizedUsers,
          // },
        ],
        // requiredModule: 'false',
      },
      {
        title: 'Integrations',
        key: 'integrations',
        icon: 'LayersIcon',
        children: [
          {
            title: 'Autenti',
            key: 'autenti',
            component: Autenti,
          },
          {
            title: 'ImageRecognition',
            key: 'imageRecognition',
            component: ImageRecognition,
            requiredModule: 'imageRecognitionModule',
          },
          {
            title: 'Fakturownia',
            key: 'fakturownia',
            component: Fakturownia,
            requiredPermissions: ['FULL_ACCESS'],
          },
          {
            title: 'Zapier',
            key: 'zapier',
            component: Zapier,
          },
          {
            title: 'Make',
            key: 'make',
            component: Make,
          },
          {
            title: 'Ringostat',
            key: 'ringostat',
            component: Ringostat,
            requiredModule: 'callModule',
          },
          {
            title: 'Google',
            key: 'google',
            component: Google,
            requiredPermissions: ['FULL_ACCESS'],
          },
        ],
      },
      // Threads & Contacts
      {
        title: 'ThreadsAndContacts',
        key: 'threads-and-contacts',
        icon: 'UsersIcon',
        children: [
          {
            title: 'Contacts',
            key: 'contacts',
            component: ContactSettings,
          },
          {
            title: 'Threads',
            key: 'threads',
            component: ThreadSettings,
          },
          {
            title: 'Polls',
            key: 'polls',
            component: Polls,
            requiredModule: 'pollModule',
          },
          {
            title: 'ContactGenerator',
            key: 'contactGenerator',
            component: ContactGenerator,
            // requiredDomain: ['4b', 'lubolandia', 'test', 'dlsk'],
            requiredModule: 'shortcutModule',
          },
        ],
      },

      {
        title: 'Applications',
        key: 'applications',
        icon: 'ArchiveIcon',
        children: [
          {
            title: 'General',
            key: 'general',
            component: Applications,
          },
          {
            title: 'CloseReasons',
            key: 'closeReasons',
            component: CloseReasons,
          },
        ],
        requiredModule: 'applicationModule',
      },

      // Cart
      {
        title: 'Offer.Cart',
        key: 'cart',
        icon: 'CreditCardIcon',
        children: [
          {
            title: 'General',
            key: 'general',
            component: OfferSettings,
          },
          {
            title: 'Shop',
            key: 'shop',
            component: Shop,
          },
          {
            title: 'ListWebsite',
            key: 'listWebsite',
            component: List,
          },
          {
            title: 'ImageTypes',
            key: 'imageTypes',
            component: ProductPictureTypes,
          },
        ],
        requiredModule: 'offerModule',
      },

      // Agreements Count
      {
        title: 'AgreementsCount',
        key: 'agreements',
        icon: 'FileIcon',
        children: [
          {
            title: 'ContractGenerator',
            key: 'contractGenerator',
            component: ContractsGenerator,
          },
          {
            title: 'AgreementTypes',
            key: 'agreementTypes',
            component: AgreementTypes,
          },
          {
            title: 'Components',
            key: 'components',
            component: ComponentsTemplate,
          },
        ],
        requiredModule: 'agreementModule',
        viewPermissions: [
          [SHOW_SYSTEM_MENU_AGREEMENTS],
        ],
      },

      // Projects
      {
        title: 'Projects',
        key: 'projects',
        icon: 'GridIcon',
        children: [
          {
            title: 'Settings',
            key: 'settings',
            component: Status,
          },
          {
            title: 'Tags',
            key: 'tags',
            component: TagsList,
          },
          {
            title: 'Categories',
            key: 'categories',
            component: CategoriesList,
          },
        ],
        viewPermissions: [
          [SHOW_SYSTEM_MENU_PROJECTS],
        ],
        requiredModule: 'projectModule',
      },

      // Personal AI
      {
        title: 'PersonalAI',
        key: 'ai',
        icon: 'MessageSquareIcon',
        children: [
          {
            title: 'General',
            key: 'general',
            component: Ai,
          },
          {
            title: 'BugReports',
            key: 'bugReports',
            component: BugReports,
          },
          {
            title: 'Reports',
            key: 'reports',
            component: Reports,
          },
        ],
        requiredModule: 'chatGptModule',
      },
      {
        title: 'Automations',
        key: 'automations',
        icon: 'CpuIcon',
        children: [
          {
            title: 'General',
            key: 'general',
            component: Automations,
          },
          {
            title: 'EventLog',
            key: 'eventLog',
            component: AutomationLogs,
          },
        ],
        requiredModule: 'automationModule',
        requiredPermissions: [AUTOMATION_VIEW_ALL],
      },
    ],
  }),

  computed: {
    list() {
      return this.tabs
        .filter(tab => !tab?.viewPermissions || this.checkRequiredViewPermissions(tab.viewPermissions))
        .filter(tab => !tab?.requiredModule || this.checkRequiredModule(tab.requiredModule))
        .filter(tab => !tab?.requiredPermissions || this.checkRequiredPermissions(tab.requiredPermissions))
        .map(tab => ({ ...tab, children: this.allowChild(tab) }))
        .filter(tab => tab.children.length)
    },
  },

  methods: {
    allowChild(activeTab) {
      return activeTab.children
        .filter(tab => !tab?.viewPermissions || this.checkRequiredViewPermissions(tab.viewPermissions))
        .filter(tab => !tab?.requiredModule || this.checkRequiredModule(tab.requiredModule))
        .filter(tab => !tab?.requiredPermissions || this.checkRequiredPermissions(tab.requiredPermissions))
        .filter(tab => !tab?.requiredDomain || this.checkRequiredHostnameFragments(tab.requiredDomain))
    },

    changeRoute(base, tab) {
      window.history.replaceState(null, null, `/settings/${base}/${tab}`)
    },
  },
}
</script>
