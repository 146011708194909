<template>
  <div>
    <b-row>
      <b-col sm="12">
        <b-button
          size="sm"
          class="mb-50"
          variant="primary"
          :disabled="loading"
          @click="onSaveSettings"
        >
          <span v-if="!loading">{{ $t('Save') }}</span>
          <b-spinner
            v-else
            small
            variant="light"
          />
        </b-button>
      </b-col>

      <b-col
        sm="12"
      >
        <b-card>
          <template #header>
            <h4 class="mb-0 pb-0">
              {{ $t('System') }}
            </h4>
          </template>
          <b-form-group :label="$t('SystemName')">
            <b-form-input
              v-model="system.name"
            />
          </b-form-group>

          <b-row style="row-gap: 1rem">
            <b-col
              cols="12"
              md="7"
              lg="4"
              xl="3"
            >
              <sw-form-input-image
                v-model="system.logo"
                :label="$t('Logo')"
                :info="$tc('file.Max', 1, { size: 100 })"
                fluid-x
                max-size="100"
                :clearable="false"
                @input="e => onChange('logo', e.fr)"
              />
            </b-col>

            <b-col
              cols="12"
              md="5"
              lg="3"
              xl="2"
            >
              <sw-form-input-image
                v-model="system.signet"
                :label="$t('Signet')"
                :info="$tc('file.Max', 1, { size: 50 })"
                fluid-x
                max-size="50"
                :clearable="false"
                @input="e => onChange('signet', e.fr)"
              />
            </b-col>

            <b-col
              cols="12"
              md="5"
              lg="3"
              xl="2"
            >
              <sw-form-input-image
                v-model="system.favicon"
                label="Favicon"
                :info="$tc('file.Max', 1, { size: 25 })"
                fluid-x
                max-size="25"
                :clearable="false"
                @input="e => onChange('favicon', e.fr)"
              />
            </b-col>

            <b-col
              cols="12"
              md="5"
              lg="3"
              xl="2"
            >
              <sw-form-input-image
                v-model="system.aiAvatar"
                :label="$t('AiAvatar')"
                :info="$tc('file.Max', 1, { size: 50 })"
                fluid-x
                max-size="50"
                placeholder="fa-solid fa-wand-magic-sparkles"
                placeholder-size="3x"
                :clearable="false"
                @input="e => onChange('aiAvatar', e.fr)"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>

      <b-col v-if="false"
             sm="12"
             class="mb-1"
      >
        <app-collapse>
          <app-collapse-item :title="$t('Tables')">
            <b-alert show
                     variant="primary"
            >
              <div class="alert-body">
                {{ $t('FirstDefinitionOfTables') }}
              </div>
            </b-alert>

            <empty-content v-if="user.configTables === null" />
            <div v-else>
              <div v-for="([key, item], index) in Object.entries(user.configTables)"
                   :key="`table_${index}`"
              >
                <div v-if="getRouteAndNavigationByName(key.split('_table_columns_')) && allowVisible(getRouteAndNavigationByName(key.split('_table_columns_')))">
                  <b-card header-class="mb-0 pb-0">
                    <template #header>
                      <div class="d-flex">
                        <sw-icon class="text-primary mr-25"
                                 color="primary"
                                 :icon="getRouteAndNavigationByName(key.split('_table_columns_')).icon"
                        />
                        <h4 class="mb-50 pb-50">
                          {{ $t(getRouteAndNavigationByName(key.split('_table_columns_')).title) }}
                        </h4>
                      </div>
                    </template>
                    <b-badge v-for="(tableRow, tableRowIndex) in (typeof item === 'string' ? JSON.parse(item) : item)"
                             :key="`table_row_${tableRowIndex}_${index}`"
                             :variant="tableRow.label === '#' ? 'secondary' : (tableRow.visible ? 'primary' : 'light-primary')"
                             class="m-25"
                    >
                      {{ $t(tableRow.label) }}
                    </b-badge>
                  </b-card>
                  <hr>
                </div>
              </div>
            </div>

          </app-collapse-item>
        </app-collapse>
      </b-col>

      <b-col sm="12"
             class="mb-1"
      >
        <app-collapse>
          <app-collapse-item :title="$t('Menu')">
            <b-row>
              <b-col md="6">
                <h6 class="mb-75">
                  {{ $t('Mains') }}
                </h6>

                <draggable
                  v-model="menuList"
                  tag="ul"
                  group="people"
                  class="w-100 list-group list-group-flush cursor-move rounded border mb-1"
                  style="min-height: 3rem;"
                  2
                >
                  <template v-for="(item, index) in menuList">
                    <b-list-group-item
                      v-if="canViewVerticalNavMenuLink(navMenuItems.find(i => i.key === item.key))"
                      :key="index"
                      tag="li"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="column-gap: .5rem"
                      >
                        <b-avatar
                          variant="light-primary"
                          rounded
                          size="sm"
                        >
                          <sw-icon :icon="navMenuItems.find(i => i.key === item.key).icon || 'MinusIcon'" />
                        </b-avatar>

                        <p class="h5 text-primary mb-0 text-capitalize">
                          {{ $t(navMenuItems.find(i => i.key === item.key).title) }}
                        </p>
                      </div>
                    </b-list-group-item>
                  </template>
                </draggable>
              </b-col>

              <b-col md="6">
                <h6 class="mb-75">
                  {{ $tc('offer.status.HIDDEN', 2) }}
                </h6>

                <draggable
                  v-model="menuHideList"
                  tag="ul"
                  group="people"
                  class="w-100 list-group list-group-flush cursor-move rounded border"
                  style="min-height: 3rem;"
                >
                  <template v-for="(item, index) in menuHideList">
                    <b-list-group-item
                      v-if="canViewVerticalNavMenuLink(navMenuItems.find(i => i.key === item.key))"
                      :key="index"
                      tag="li"
                    >
                      <div
                        class="d-flex align-items-center"
                        style="column-gap: .5rem"
                      >
                        <b-avatar
                          variant="light-primary"
                          rounded
                          size="sm"
                        >
                          <sw-icon :icon="navMenuItems.find(i => i.key === item.key).icon || 'MinusIcon'" />
                        </b-avatar>

                        <p class="h5 text-primary mb-0 text-capitalize">
                          {{ $t(navMenuItems.find(i => i.key === item.key).title) }}
                        </p>
                      </div>
                    </b-list-group-item>
                  </template>
                </draggable>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-col>

      <b-col sm="12"
             class="mb-1"
      >
        <app-collapse>
          <app-collapse-item :title="$t('Colors')">
            <div class="d-flex align-items-center mb-1">
              <div class="font-medium-1 font-weight-bold text-primary mr-50">
                {{ $t('Styles.PrimaryColors') }}
              </div>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="colors = { ...defaultColors }; onSaveSettings(); setDefaultColors()"
              >
                {{ $t('RestoreDefault') }}
              </b-button>
            </div>

            <b-row
              class="text-center"
              style="row-gap: .5rem"
            >
              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.primary)"
                  :default-color="getFormatedColor(defaultColors.primary)"
                  :label="$t('Styles.Primary')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'primary')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('primary')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.info)"
                  :default-color="getFormatedColor(defaultColors.info)"
                  :label="$t('Styles.Info')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'info')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('info')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.danger)"
                  :default-color="getFormatedColor(defaultColors.danger)"
                  :label="$t('Styles.Danger')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'danger')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('danger')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.warning)"
                  :default-color="getFormatedColor(defaultColors.warning)"
                  :label="$t('Styles.Warning')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'warning')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('warning')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.secondary)"
                  :default-color="getFormatedColor(defaultColors.secondary)"
                  :label="$t('Styles.Secondary')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'secondary')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('secondary')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.success)"
                  :default-color="getFormatedColor(defaultColors.success)"
                  :label="$t('Styles.Success')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'success')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('success')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.dark)"
                  :default-color="getFormatedColor(defaultColors.dark)"
                  :label="$t('Styles.Dark')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'dark')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('dark')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.light)"
                  :default-color="getFormatedColor(defaultColors.light)"
                  :label="$t('Styles.Light')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'light')"
                >
                  <template #toolbar>
                    <sw-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="openColorPreview('light')"
                    />
                  </template>
                </sw-form-input-color>
              </b-col>
            </b-row>

            <!-- Other colors -->
            <div class="d-flex align-items-center my-1">
              <div class="font-medium-1 font-weight-bold text-primary mr-50">
                {{ $t('Styles.Others') }}
              </div>
            </div>
            <b-row
              class="text-center"
              style="row-gap: .5rem"
            >
              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.menu)"
                  :default-color="getFormatedColor(defaultColors.menu)"
                  :label="$t('Styles.Menu')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'menu')"
                />
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors['font-menu'])"
                  :default-color="getFormatedColor(defaultColors['font-menu'])"
                  :label="$t('Styles.MenuFont')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'font-menu')"
                />
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors['active-menu-icon-font'])"
                  :default-color="getFormatedColor(defaultColors['active-menu-icon-font'])"
                  :label="$t('Styles.MenuIconFont')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'active-menu-icon-font')"
                />
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors['active-menu'])"
                  :default-color="getFormatedColor(defaultColors['active-menu'])"
                  :label="$t('Styles.ActiveMenu')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'active-menu')"
                />
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors['active-menu-font'])"
                  :default-color="getFormatedColor(defaultColors['active-menu-font'])"
                  :label="$t('Styles.ActiveMenuFont')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'active-menu-font')"
                />
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.background)"
                  :default-color="getFormatedColor(defaultColors.background)"
                  :label="$t('Styles.Background')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'background')"
                />
              </b-col>

              <b-col
                sm="6"
                md="4"
                lg="3"
              >
                <sw-form-input-color
                  :value="getFormatedColor(colors.card)"
                  :default-color="getFormatedColor(defaultColors.card)"
                  :label="$t('Styles.Cards')"
                  fluid-x
                  @input="onChangeColor($event.hex, 'card')"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-col>

      <b-col sm="12"
             class="mb-1"
      >
        <app-collapse>
          <app-collapse-item :title="$t('FontsAndSpaces')">
            <div class="d-flex align-items-center mb-1">
              <div class="font-medium-1 font-weight-bold text-primary mr-50">
                {{ $t('Styles.Fonts') }}
              </div>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="sizes = { ...defaultSizes }; onSaveSettings(); setDefaultSizes()"
              >
                {{ $t('RestoreDefault') }}
              </b-button>
            </div>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <div>{{ $t('Styles.GlobalFont') }}</div>
                <b-form-input
                  v-model="sizes['font-global']"
                  @blur="onChangeSizing($event, 'font-global')"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <div>{{ $t('Styles.TableHeadFont') }}</div>
                <b-form-input
                  v-model="sizes['font-table-header']"
                  @blur="onChangeSizing($event, 'font-table-header')"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <div>{{ $t('Styles.TableCellFont') }}</div>
                <b-form-input
                  v-model="sizes['font-table-cell']"
                  @blur="onChangeSizing($event, 'font-table-cell')"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <div>{{ $t('Styles.MenuFont') }}</div>
                <b-form-input
                  v-model="sizes['font-menu']"
                  @blur="onChangeSizing($event, 'font-menu')"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <sw-select :name="$t('Styles.Typeface')">
                  <v-select
                    v-model="sizes['font-family']"
                    :options="fonts"
                    @input="onChangeSizing($event, 'font-family')"
                  />
                </sw-select>
              </b-col>
            </b-row>

            <div class="d-flex align-items-center mb-1">
              <div class="font-medium-1 font-weight-bold text-primary mr-50">
                {{ $t('Styles.Spaces') }}
              </div>
            </div>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <div>{{ $t('Styles.MenuWidth') }}</div>
                <b-form-input
                  v-model="sizes['width-menu']"
                  @blur="onChangeSizing($event, 'width-menu')"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <div>{{ $t('Styles.VerticalPadding') }}</div>
                <b-form-input
                  v-model="sizes['table-padding-v']"
                  @blur="onChangeSizing($event, 'table-padding-v')"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="4"
                class="mb-75"
              >
                <div>{{ $t('Styles.HorizontalPadding') }}</div>
                <b-form-input
                  v-model="sizes['table-padding-h']"
                  @blur="onChangeSizing($event, 'table-padding-h')"
                />
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-col>

      <b-col sm="12"
             class="mb-1"
      >
        <app-collapse>
          <app-collapse-item :title="$t('Styles.Others')">
            <b-form-checkbox v-model="system.removeGrossEnds"
                             variant="primary"
            >
              {{ $t('DisableGrossPriceEnds') }}
            </b-form-checkbox>
          </app-collapse-item>
        </app-collapse>
      </b-col>

      <!-- Save Button -->
      <b-col>
        <b-button
          size="sm"
          class="mb-50"
          variant="primary"
          :disabled="loading"
          @click="onSaveSettings"
        >
          <span v-if="!loading">{{ $t('Save') }}</span>
          <b-spinner
            v-else
            small
            variant="light"
          />
        </b-button>
      </b-col>

      <!-- Color Preview Modal -->
      <b-modal
        :visible="!!colorPreview"
        :title="$t('Styles.ColorsPreview')"
        button-size="sm"
        ok-only
        :ok-title="$t('Close')"
        ok-variant="secondary"
        @hide="colorPreview = null"
      >
        <color-preview
          v-if="colorPreview"
          :color="colorPreview"
        />
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SAVE_SETTINGS, UPDATE_NAV } from '@/@constants/mutations'
import ColorPreview from '@/views/core/admin/ColorPreview.vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import { canViewVerticalNavMenuLink } from '@core/libs/acl/utils'
import navMenuItems from '@/navigation/vertical'

const defaultColors = {
  primary: '115, 103, 240',
  info: '0, 207, 232',
  danger: '234, 84, 85',
  warning: '255, 159, 67',
  secondary: '130, 134, 139',
  success: '40, 199, 111',
  dark: '75, 75, 75',
  light: '246, 246, 246',
  menu: '255, 255, 255',
  'font-menu': '98, 95, 110',
  'active-menu': '115, 103, 240',
  'active-menu-font': '184, 184, 184',
  background: '240, 240, 240',
  card: '255, 255, 255',
  'active-menu-icon-font': '98, 95, 110',
}

const elementsWithFonts = {
  menu: ['font-menu', 'active-menu-icon-font'],
  'active-menu': ['active-menu-font'],
}

const defaultSizes = {
  'font-family': 'Montserrat',
  'font-global': '0.9rem',
  'font-menu': '0.9rem',
  'width-menu': '230px',
  'font-table-header': '0.7rem',
  'font-table-cell': '0.8rem',
  'table-padding-v': '5px',
  'table-padding-h': '10px',
}

export default {
  name: 'System',
  components: {
    ColorPreview,
    vSelect,
    draggable,
  },
  data: () => ({
    navMenuItems,

    defaultColors,
    elementsWithFonts,
    defaultSizes,
    test: null,
    loading: false,
    colorPreview: null,
    colors: { ...defaultColors },
    sizes: { ...defaultSizes },
    fonts: [
      'Arial',
      'Verdana',
      'Trebuchet',
      'Times New Roman',
      'Tahoma',
      'Lucida Sans Unicode',
      'Georgia',
      'Courier',
      'Montserrat',
      'Century gothic',
      'Rubik',
    ],

    menuList: [],
    menuHideList: [],
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      user: 'auth/getCurrentUser',
      nav: ('verticalMenu/itemsFull'),
    }),
  },
  watch: {
    nav: {
      deep: true,
      handler() { this.updateNav() },
    },
  },
  mounted() {
    this.loadNav()

    if (this.system?.styles) {
      if (this.system.styles?.colors) {
        Object.assign(this.colors, this.system.styles.colors)
      }
      if (this.system.styles?.sizes) {
        Object.assign(this.sizes, this.system.styles.sizes)
      }
    }
  },
  methods: {
    canViewVerticalNavMenuLink,
    routeFilter(route) {
      let allow = true
      if (route?.requiredModule?.length) {
        allow = this.checkRequiredModule(route.requiredModule)
        if (!allow) return false
      }

      if (!route?.requiredPermissions && route?.viewPermissions && allow) {
        return this.checkRequiredViewPermissions(...route.viewPermissions)
      }

      if (route?.requiredPermissions && route?.viewPermissions && allow) {
        return this.checkRequiredPermissions(...route.requiredPermissions) && this.checkRequiredViewPermissions(...route.viewPermissions)
      }

      if (route?.requiredPermissions && allow) {
        return this.checkRequiredPermissions(...route.requiredPermissions)
      }

      return true
    },
    loadNav() {
      this.loading = true

      this.$store.dispatch(`verticalMenu/${UPDATE_NAV}`)
        .then(() => this.updateNav())
        .finally(() => { this.loading = false })
    },
    async updateNav() {
      this.loading = true

      this.$set(this, 'menuList', this.nav().filter(item => this.routeFilter(item)).filter(item => !item.hide))
      this.$set(this, 'menuHideList', this.nav().filter(item => this.routeFilter(item)).filter(item => item.hide))

      setTimeout(async () => {
        await this.$store.dispatch(`verticalMenu/${UPDATE_NAV}`)
        this.$forceUpdate()
      }, 250)

      this.loading = false
    },
    rgbToHex(c) {
      // eslint-disable-next-line radix
      const hex = parseInt(c).toString(16)
      return hex.length === 1 ? `0${hex}` : hex
    },
    setDefaultSizes() {
      Object.assign(this.sizes, this.defaultSizes)
      Object.keys(this.defaultSizes).forEach(size => {
        this.onChangeSizing(this.defaultSizes[size], size)
      })
    },
    setDefaultColors() {
      Object.assign(this.colors, this.defaultColors)
      Object.keys(this.defaultColors).forEach(color => {
        this.onChangeColor(this.getFormatedColor(this.defaultColors[color]), color)
      })
    },
    getRouteAndNavigationByName(name) {
      const existedNav = this.navMenuItems.find(e => e.route.replaceAll('-', '_').replaceAll('/', '_').toLowerCase().includes(name[0].toLowerCase()) || name[0].toLowerCase().includes(e.route.replaceAll('-', '_').replaceAll('/', '_').toLowerCase()))
      return existedNav
    },
    allowVisible(nav) {
      if (!nav) return false
      return (!nav?.viewPermissions || this.checkRequiredViewPermissions(nav.viewPermissions))
          && (!nav?.requiredModule || this.checkRequiredModule(nav.requiredModule))
          && (!nav?.requiredPermissions || this.checkRequiredPermissions(nav.requiredPermissions))
    },
    getFormatedColor(color) {
      // Color format  0, 0, 0 -> #000000
      const colors = color.split(', ')
      const r = this.rgbToHex(colors[0])
      const g = this.rgbToHex(colors[1])
      const b = this.rgbToHex(colors[2])
      return `#${r}${g}${b}`
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      } : null
    },
    getFontColor(backgroundColor) {
      // Calculate the perceived brightness of the background color
      const bgBrightness = (0.299 * backgroundColor.r) + (0.587 * backgroundColor.g) + (0.114 * backgroundColor.b)

      // Calculate the threshold for determining whether to use a light or dark font color
      const threshold = 128

      // If the background color is bright, use a dark font color
      if (bgBrightness > threshold) {
        return '#4d4d4d'
      }

      // Otherwise, use a light font color
      return '#fafafa'
    },
    onChangeColor(color, colorKey) {
      // Color format #000000 -> 0, 0, 0
      const rgb = this.hexToRgb(color)
      const formatedColor = `${rgb.r}, ${rgb.g}, ${rgb.b}`
      this.$set(this.colors, colorKey, formatedColor)

      const fontList = this.elementsWithFonts[colorKey]

      if (fontList) {
        const fontColor = this.getFontColor(rgb)

        fontList.forEach(f => this.onChangeColor(fontColor, f))
      }

      document.documentElement.style.setProperty(`--theme-color-${colorKey}`, formatedColor)
    },
    onChangeSizing(value, key) {
      document.documentElement.style.setProperty(`--theme-${key}`, value?.target?.value ? value.target.value : value)
    },
    openColorPreview(color) {
      this.$set(this, 'colorPreview', color)
    },
    onTargetInput(ref) {
      this.$refs[ref].click()
    },
    onChange(option, fr) {
      this.system[option] = fr.result
    },
    async onSaveSettings() {
      this.loading = true
      const styles = {
        colors: this.colors,
        sizes: this.sizes,
      }

      const menuList = this.menuList.map(({ key }, i) => ({ key, position: i, hide: false }))
      const menuHideList = this.menuHideList.map(({ key }, i) => ({ key, position: i, hide: true }))

      const nav = menuList.concat(menuHideList)

      try {
        await this.$store.dispatch(`system/${SAVE_SETTINGS}`, {
          styles,
          nav,
          privateData: {
            name: this.system.name,
            logo: this.system.logo,
            signet: this.system.signet,
            favicon: this.system.favicon,
            aiAvatar: this.system.aiAvatar,
            removeGrossEnds: this.system.removeGrossEnds,
          },
        })

        setTimeout(async () => {
          await this.$store.dispatch(`verticalMenu/${UPDATE_NAV}`)
          this.$forceUpdate()
        }, 250)

        this.showToast('success', this.$i18n.t('ChangesSaved'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .color-box {
    margin: 0 auto;
    width: 75px;
    height: 50px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, .05), 0 10px 10px rgba(0, 0, 0, .05);
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.color-input {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: none !important;
  border: 0 !important;
  cursor: pointer !important;
  height: 6rem !important;
  padding: 0 !important;
}
</style>
